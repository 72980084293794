import React, { useState, useEffect, useContext } from 'react';
import Header from "./Header";
import Footer from "./Footer";
import {Helmet} from "react-helmet";
import Context, { ContextProvider } from "./Context";
import Overlay from "./Overlay";
import {navigate} from 'gatsby'; //import navigate from gatsby


import ContactForm from "./ContactForm";

const Layout = ({location, path, children}) => {
    const context = useContext(Context);

    const [supportVisible, setSupportVisible] = useState(false);
    const [teamVisible, setTeamVisible] = useState(false);

    const {href} = location;

    const menu = [
        {
            label: 'Rental',
            href: path === '/' ? "#rental" : "/#rental",
            useLinkComponent: true,
            current: href && href.includes('#rental'),
            submenuItems: null,
        },
        {
            label: 'Streaming',
            href: path === '/' ? "#streaming" : "/#streaming",
            useLinkComponent: true,
            current: href && href.includes('#streaming'),
            submenuItems: null,
        },
        {
            label: 'Dailycious',
            href: path === '/' ? "#dailycious" : "/#dailycious",
            useLinkComponent: true,
            current: href && href.includes('#dailycious'),
            submenuItems: null,
        },
        {
            label: 'Storage',
            href: path === '/' ? "#storage" : "/#storage",
            useLinkComponent: true,
            current: href && href.includes('#storage'),
            submenuItems: null,
        },
        {
            label: 'Contact',
            href: path === '/' ? "#contact" : "/#contact",
            useLinkComponent: true,
            current: href && href.includes('#contact'),
            submenuItems: null,
            onClick: () => {
                setSupportVisible(true);
            }
        },
        {
            label: 'Team',
            href: path === '/' ? "#team" : "/#team",
            useLinkComponent: true,
            current: href && href.includes('#team'),
            submenuItems: null,
            onClick: () => {
                setTeamVisible(true);
            }
        },
        {
            label: 'CGL',
            href: 'pages/conditions-generales-de-locations',
            useLinkComponent: true,
            current: href && href.includes('#team'),
            submenuItems: null
        },
    ];

    const team = [
        {
            name: "Mathieu LECLERCQ",
            role: "Workflow Specialist",
            email: "mathieu@be4post.com",
        },
        {
            name: "Guillaume POIRSON",
            role: "CTO",
            email: "guillaume@be4post.com",
        },
        {
            name: "Philippe GUILLEMAIN",
            role: "Sales executive",
            email: "philippe@be4post.com",
        },
        {
            name: "Aurélie DI PASCALE",
            role: "Sales representative",
            email: "aurelie@be4post.com",
        },
        {
            name: "Joachim LE SOURD JOUAN",
            role: "Technician",
            email: "joachim@be4post.com",
        },
    ];

    const onClickFooterElement = (type) => {
        switch (type) {
            case 'support':
                setSupportVisible(true);
                break;
        }
    }

    return (
        <ContextProvider value={context}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Be4Post - Where Post Meets Production</title>
                <link rel="canonical" href="https://www.be4post.com" />
                <meta name="description" content="Be4Post provides high performance mobile stations designed for dailies prep, live color grading, data management and/or DIT servicing" />
            </Helmet>
            <Header location={location} path={path} menu={menu} />
            <main>
                {children}
            </main>

            {supportVisible ?
                <Overlay noPadding={true} onCloseHandler={() => setSupportVisible(false)} contentBoxWidth="81rem">
                    <ContactForm />
                </Overlay>
                :
                null
            }

            {teamVisible ?
                <Overlay title={"Team"} noSubtitle={true} onCloseHandler={() => setTeamVisible(false)} contentBoxWidth="47rem">
                    <div className="team-container">
                        <div className="row">
                            {team.map((person, index) =>
                                    <div className="col-md-6" key={index}>
                                        <div className="team">
                                            <div className="team__name">{person.name} <span className="team__role">{person.role}</span></div>
                                            <div className="team__email"><a href={`mailto:${person.email}`} rel="noreferrer" target="_blank">{person.email}</a></div>
                                        </div>
                                    </div>
                            )}
                        </div>
                    </div>
                </Overlay>
                : null}

            <Footer onClickFooterElement={onClickFooterElement}/>


            <form  name="contact" method="POST" data-netlify="true" className="d-none">
                <input type="hidden" form-name="contact" />
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="support-company-production">Company / Production</label>
                            <input type="text" name="company-production" className="form-control" id="support-company-production" required />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="support-first-name">First Name</label>
                            <input type="text" name="first_name" className="form-control" id="support-first-name" required />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="support-last-name">Last Name</label>
                            <input type="text" name="last_name" className="form-control" id="support-last-name" required />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="support-phone">Phone number</label>
                            <input type="text" name="phone" className="form-control" id="support-phone" required />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="support-email">Email address</label>
                            <input type="email" name="email" className="form-control" id="support-email" required />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="support-subject">Subject</label>
                            <input type="text" name="subject" className="form-control" id="support-subject" required />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="support-message">Message</label>
                            <textarea name="message" className="form-control" id="support-message" required rows={3} />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-submit-container">
                            <div>
                                <button type="submit" className="button-2">Send</button>
                            </div>
                            <div>
                                <p>
                                    By clicking on "Send message", I agree to be contacted by the Be4Post teams and that the data filled in this form be used for update notifications by email.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ContextProvider>
    )
}

export default Layout
